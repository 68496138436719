'use client' 

import { useEffect } from 'react'
 
export default function Error({ error, reset }) {
  useEffect(() => {
    console.error(error)
  }, [error])
 
  return (
    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-w-[500px] flex flex-col items-center justify-center">
        <h2 className="text-black text-[25px] md:text-[40px] font-[600] w-full text-center">Something went wrong!</h2>
        <button
            className="w-[120px] mt-2 h-[50px] rounded-md bg-primary text-white mx-auto font-[400] text-[16px]"
            onClick={
            () => reset()
            }
        >
            Try again
        </button>
    </div>
  )
}